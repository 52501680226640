import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from "crypto-js";
import { browserName, browserVersion } from "react-device-detect";
//import { setUserSession } from './Utils/Common';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
 
  // handle button click of login form
  const handleLogin = async() => {
    setError(null);
    setLoading(true);
    var passwordEncrypted = CryptoJS.AES.encrypt(password.value, 'ekYy9ELV6YbTvlUKg5gqB7QcBq1ApJGy').toString();
    console.log("passwordEncrypted =", passwordEncrypted);
    //await getIPAddressAndBrowserData();
    const res = await axios.get('https://geolocation-db.com/json/')
    var UserIPAddress = res.data.IPv4;
    var UserBrowserName = `${browserName}`;

    //console.log(res.data.IPv4);
    //console.log(`${browserName} ${browserVersion}`);

    console.log('userIPAddress =', UserIPAddress);
    console.log('userBrowserName = ',UserBrowserName);
    axios.post('https://devaws-coherentrx-peg.coherentrx.com/users/login', { email: username.value, password: passwordEncrypted, IP:UserIPAddress, browserName:UserBrowserName},{
      withCredentials: true,
      headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'
  }}).then(response => {
      setLoading(false);
      console.log("redirect");
      window.location.href="https://dev-aws-dash-peg.coherentrx.com/header/index.html"
      //setUserSession(response.data.token, response.data.user);
      //props.history.push('/dashboard');
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError(error.response.data.message);
      else setError("Something went wrong. Please try again later.");
    });
  }

  return (
    <div id="TopImg">
      <div id="formField" class="nB bckPR">
        <div id="signIn" class="mlrA w510p txtCenter " style={{ marginTop: '20px', width: '340px'}}>
          <p class="px20 WtMedium B txtLeft pl25 mb20 logPtxtC reg_title" style={{color:'#0e08ae'}}>Sign in with your DEERS Logon</p>          
          <div class="field rad5 outlineOrangeYellow mb15 mlr25 bkgOffWhite bBoxSize oFlowNo">
            <input class="plr15 ptb15 w100 mAll0 gouthammedium inst" type="text" {...username} autoComplete="new-password" placeholder="email@example.com"/>
          </div>
          <div class="field loginpass rel rad5 outlineOrangeYellow mlr25 bkgOffWhite bBoxSize oFlowNo mb10">
            <input class="passT plr15 ptb15 w100 mAll0 gouthammedium inst" type="password" {...password} autoComplete="new-password" placeholder="Password1234"/>
          </div>
          {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
          <div style={{ textAlign: 'right', paddingTop: '10px', paddingRight: '24px', paddingBottom: '24px' }}>
              <a href={{void(){}}} id="forgotPrompt" class="Gotham bold nB vCenter">Reset password</a>
          </div>
          <input class="ptb10 plr60 orangeyellowBkg white px15 GothamM pointer" style={{borderRadius : '80px', backgroundColor: "#0e08ae"}} type="button" value={loading ? 'Loading...' : 'Sign in with your DEERS Logon'} onClick={handleLogin} disabled={loading} /><br />
        </div>
      </div>
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;