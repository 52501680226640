import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, NavLink } from 'react-router-dom';

import Login from './Login';

function App() {


  return (
    <div className="App">
      <BrowserRouter basename="/login">
        <div>
         <Login />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
